/* You can add global styles to this file, and also import other style files */
/* styles.css */
@import url("https://fonts.googleapis.com/css2?family=Bagel+Fat+One&family=Red+Hat+Display:wght@500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:ital,wght@0,600;0,700;1,600;1,700&display=swap');
@import url("node_modules/@fortawesome/fontawesome-free/css/all.min.css");
/* styles.css or styles.scss */

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

/* .auth-page-background {
  background-image: url('../src/assets/auth-background-img.svg');
  background-size: cover;
  background-position: center;
  z-index: 1;
} */
/* body {
  margin: 0;
} */
/* auth bg image */
.auth-background-image {
  position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('../src/assets/auth-background-img.svg');
    background-size: cover;
    background-position: center;
    z-index: -1; /* Ensure the background is behind the content */
  }
@media screen and (min-width: 1920px){
.auth-main-container {
padding-top: 20rem;
}
}

@media screen and (min-width: 1280px) and (max-width: 1919px){
  .auth-main-container {
    padding-top: 18rem;
  }
  }


  .auth-div {
    position: relative;
    width: 100%;
  height: 100%
  }

  .auth-div .logo {
    position: absolute;
    top: 3rem;
    left: 8rem;
  }
.auth-form-container {
  width: 19rem;
  margin-left: 10rem;
}

@media(min-width: 1920px){
.auth-form-container {
  width: 29rem;
  padding-top: 1rem;
}
}
#auth-btn {
  height: 3rem;
  width: 100%;
  color: #FFFFFF;
  font-size: 1rem;
  border: none;
  border-radius: 0.5rem;
  background: linear-gradient(to right, #7FC800, #60AA3F);
  cursor: pointer;
}

.auth-container {
  width: 9rem;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 1rem;
}

.account {
  font-family: Red Hat Display;
  color: #686868;
}

#auth-link {
  text-decoration: none;
  color: #006F44;
  font-family: Red Hat Display;
}

#auth-text {
  display: block;
  text-align: center;
}

.fpo-profile-container {
  min-height: 100vh;
  background-color: #F9FFE3;
  padding-top: 15rem;
  /* overflow-y: scroll; */
}


.background-container {
  position: fixed;
  bottom: 0;
  right: 2;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.background-image {
  width: 100%;
  height: 100%;
  /* background-image: url('../../../assets//fpo-details-background-img.svg'); */
  background-size: 50% auto;
  background-repeat: no-repeat;
  background-position: bottom right;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.error-container {
  color: firebrick;
   height: auto; /* before 1.25Rrem; */
  width: 20rem;
  font-size: 1rem;
  margin-left: 10rem;
}


.client-side-error-container {
  color: firebrick;
  width: 100%;
  text-align: left;
  margin-left: 0;
}


form {
  position: relative;
  margin-left: 6rem;
  z-index: 2;
}

input, select {
  height: 2.5rem;
  width: 18rem;
}

@media(min-width: 1920px){
  input {
    height: 4rem;
  width: 28rem;
  border-radius: 1rem;
  }
}
 input,textarea,select {
  z-index: 2;
  font-size: .9rem;
  color: #595959;
  padding-left: 1.25rem;
  background-color: #EDF6CB;
  border: none;
  border-radius: .5rem;
  font-family: Red Hat Display, Medium;
 }

 input,select {
  padding-top: 0;
 }
 textarea {
  padding-top: 1rem;
 }
/* ::placeholder {
  color: #595959;
} */
select {
  width: 100%;
}

/* select:focus {
   border-color: red
}

select option {
  letter-spacing: 8rem;
} */
.input-container{
  position: relative;
    margin-top: 1rem;
    font-family: Red Hat Display, Medium;
    }
    .input-container input,select,textarea {
      outline: none;
    }
    .input-container input:focus ~ .floating-label,
    .input-container textarea:focus ~ .floating-label,
    .input-container select:focus ~ .floating-label,
    .input-container input:not(:focus):valid ~ .floating-label,
    .input-container textarea:not(:focus):valid ~ .floating-label,
    .input-container select:not(:focus):valid ~ .floating-label{
      top: -.5rem;
      bottom: 2rem;
      left: 20px;
      font-size: .8rem;
      opacity: 1;
    }

    span.floating-label {
      position: absolute;
      pointer-events: none;
      left: 18px;
      top: .5rem;
      color: #595959;
      transition: 0.2s ease all;
      font-weight: lighter;
      letter-spacing: .01rem;
    }

  #next-btn {
  width: 5rem;
  height: 2.8rem;
  background: transparent linear-gradient(119deg, #7FC800 0%, #60AA3F 100%) 0% 0% no-repeat padding-box;
  border-radius: 8px;
  border-style: none;
  font-size: 0.8rem;
  color: white;
  margin-top:0.7rem;
  margin-bottom:2.2rem;
  z-index: 2;
  cursor: pointer;
}

#next-btn.a {
  cursor: pointer;

}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.add-btn {
  height: 1.5rem;
  width: 1.5rem;
  margin: 1.5rem 0;
  border: 2px solid black;
  border-radius: 50%;
  cursor: pointer;
}
.delete-btn {
  height: 1.5rem;
  width: 1.5rem;
  margin-top: .5rem;
  cursor: pointer;
}

#card-container h2 {
  color: #67B130;
  margin-left: 3rem;
  font-size: 2rem;
  font-weight: lighter;
}

.form-subpart {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* flex-wrap: wrap; */
}

